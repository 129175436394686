import { Absence } from './absence';
import { Analysis } from './analysis';
import { ApiMetaData } from './api-meta-data';
import { Department } from './department';
import { ApiConfig, Http, RoleName } from './http/http';
import { Logger } from './logger';
import { Navigation } from './navigation';
import { Notifications } from './notification';
import { Person } from './person';
import { JWTToken, Token } from './token';
import { User } from './user';

export type StatToolApi = {
  setToken: (newToken: JWTToken) => void;
  setRole: (newRole: RoleName) => void;
  absence: Absence;
  analysis: Analysis;
  department: Department;
  logger: Logger;
  navigation: Navigation;
  notification: Notifications;
  person: Person;
  user: User;
  token: Token;
  metaData: ApiMetaData;
};

export const apiBuilder = (config: ApiConfig): StatToolApi => {
  const http = new Http(config);
  const user = new User(http);
  const token = new Token(http, user.tokenRefresh.bind(user));
  http.addTokenWatcher(token);
  if (config.jwtTokenChange) {
    const callback = config.jwtTokenChange;
    http.addTokenWatcher({
      update: (jwtToken) => callback(jwtToken),
    });
  }

  const api = {
    setToken: (newToken: JWTToken) => http.setToken(newToken),
    setRole: (newRole: RoleName) => http.setRole(newRole),
    absence: new Absence(http),
    analysis: new Analysis(http),
    department: new Department(http),
    logger: new Logger(http),
    navigation: new Navigation(http),
    notification: new Notifications(http),
    person: new Person(http),
    user: user,
    token: token,
    metaData: new ApiMetaData(http),
  };

  return api;
};

export const setCurrentApi = (api: StatToolApi) => {
  _api = api;
};

let _api: StatToolApi;

export const api: StatToolApi = new Proxy(
  {},
  {
    get(_, property) {
      return (_api as any)[property];
    },
  }
) as StatToolApi;
