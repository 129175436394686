import format from 'date-fns/format';
import svLocale from 'date-fns/locale/sv';
import React, { Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { RolesAuthorizedFor } from '@/common/roles-authorization';
import { capitalize } from '@/common/utils';

import { setRole } from '../actions';
import { prettyList } from '../utils/components/navigation';
import { DepartmentPickerToggle } from './DepartmentPicker2/DepartmentPickerToggle';
import RolePicker from './RolePicker';
import SearchEmployees from './SearchEmployees';
import { withNavigation } from './hoc/withNavigation';
import Tooltip from './tooltips/Tooltip';

const renderHrLinks = (_, t) => {
  return (
    <Fragment>
      <li className="navigation__link-list-item">
        <NavLink exact to={'/hr/'}>
          {t('common.navigation.today')}
        </NavLink>
      </li>

      <li className="navigation__link-list-item">
        <NavLink exact to={'/hr/analysis'}>
          {t('common.navigation.analysis')}
        </NavLink>
      </li>

      <li className="navigation__link-list-item">
        <NavLink exact to={'/hr/report'}>
          {t('common.navigation.report')}
        </NavLink>
      </li>
    </Fragment>
  );
};

const Navigation = ({
  activeEmployeeCount,
  activeCompaniesEmployeeCount,
  context,
  date,
  employees = [],
  heading,
  user,
  match,
  t,
  i18n,
  hideSearch,
  role,
  setRole,
  history,
  companyNames,
}) => {
  const { roles } = user;

  hideSearch =
    !RolesAuthorizedFor.Query.SearchEmployees.includes(role) || hideSearch;

  return (
    <div className="navigation">
      <div className="grid__section">
        <nav className="grid__container navigation__container">
          <h2 className="navigation__heading">{heading}</h2>
          <div>
            {roles && roles.length > 1 && (
              <RolePicker
                t={t}
                roles={roles}
                role={role}
                onChange={(role) => {
                  setRole(role);
                  // go to / to trigger routing based on role
                  history.push('/');
                }}
              />
            )}
            <DepartmentPickerToggle />
          </div>

          <div className="navigation__content">
            <ul className="navigation__link-list">
              <Fragment>{renderHrLinks(match, t)}</Fragment>
            </ul>

            <ul className="navigation__crumbs">
              <li className="navigation__crumb">
                {capitalize(
                  format(date, 'dddd Do MMMM', {
                    locale: i18n.language === 'sv' ? svLocale : undefined,
                  })
                )}
              </li>
              <li className="navigation__crumb">
                <div>
                  <Tooltip
                    tooltipContent={t('common.navigation.employeesInCompany', {
                      num: activeCompaniesEmployeeCount,
                      company: prettyList(companyNames, t),
                    })}
                    position="bottom"
                  >
                    <span className="navigation__employees-link">
                      {activeEmployeeCount}
                    </span>
                  </Tooltip>{' '}
                  {t('common.navigation.numberOfEmployees')}
                </div>
              </li>
              {employees && employees.length > 0 && !hideSearch && (
                <li className="navigation__crumb navigation__crumb--search-employees">
                  <SearchEmployees employees={employees} context={context} />
                </li>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const mapStateToProps = ({ date, dataSet, user, role }) => ({
  date,
  dataSet,
  user,
  role,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setRole: (data) => dispatch(setRole(data)),
  };
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps),
  withNavigation
)(Navigation);
