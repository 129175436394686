import startOfDay from 'date-fns/start_of_day';
import subMonths from 'date-fns/sub_months';
import { combineReducers } from 'redux';
import formReducer from 'redux-form/es/reducer';
import i18next from 'i18next';
import { analysisPeriodFromDates } from './utils/period';

import {
  CHANGE_DATE,
  CHANGE_DEPARTMENT,
  CHANGE_DATASET,
  CHANGE_PERIOD,
  SET_CONDITIONS_PER_CONDITION_CATEGORY,
  SET_ROLE,
  SET_USER,
  SET_LANGUAGE,
  SET_DEPARTMENT_HIERARCHY,
  OPEN_DEPARTMENT_PICKER,
  CLOSE_DEPARTMENT_PICKER,
  OPEN_TOKEN_MODAL,
  CLOSE_TOKEN_MODAL,
} from './actions';
import {
  LOCAL_STORAGE_SELECTED_LANG_KEY,
  LOCAL_STORAGE_SELECTED_ROLE_KEY,
} from './config';
import { setKey } from './utils/localStorage';

const now = startOfDay(new Date());

const tokenReducer = (state = { isModalOpen: false }, action) => {
  switch (action.type) {
    case OPEN_TOKEN_MODAL:
      return {
        ...state,
        isModalOpen: true,
      };
    case CLOSE_TOKEN_MODAL:
      return {
        ...state,
        isModalOpen: false,
      };
    default:
      return state;
  }
};

const userReducer = (state = null, action) => {
  switch (action.type) {
    case SET_USER:
      return action.payload;
    default:
      return state;
  }
};

const departmentReducer = (state = null, action) => {
  switch (action.type) {
    case CHANGE_DEPARTMENT:
      return action.payload;
    default:
      return state;
  }
};

const dataSetReducer = (state = [], action) => {
  switch (action.type) {
    case CHANGE_DATASET:
      return action.payload;
    default:
      return state;
  }
};

const conditionsPerConditionCategoryReducer = (state = null, action) => {
  switch (action.type) {
    case SET_CONDITIONS_PER_CONDITION_CATEGORY:
      return action.payload;
    default:
      return state;
  }
};

const dateReducer = (state = now, action) => {
  switch (action.type) {
    case CHANGE_DATE:
      return action.payload;
    default:
      return state;
  }
};

const roleReducer = (state = null, action) => {
  switch (action.type) {
    case SET_ROLE:
      setKey(LOCAL_STORAGE_SELECTED_ROLE_KEY, action.payload);
      return action.payload;
    default:
      return state;
  }
};

const languageReducer = (state = null, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      setKey(LOCAL_STORAGE_SELECTED_LANG_KEY, action.payload);
      typeof document !== 'undefined' &&
        document.documentElement.setAttribute('lang', action.payload);
      i18next.changeLanguage(action.payload);
      return action.payload;
    default:
      return state;
  }
};

const periodReducer = (
  state = analysisPeriodFromDates(subMonths(now, 6), now, now),
  action
) => {
  switch (action.type) {
    case CHANGE_PERIOD:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const departmentHierarchyReducer = (state = null, action) => {
  switch (action.type) {
    case SET_DEPARTMENT_HIERARCHY:
      return action.payload;
    default:
      return state;
  }
};

const departmentPickerReducer = (state = { open: false }, action) => {
  switch (action.type) {
    case OPEN_DEPARTMENT_PICKER:
      return {
        ...state,
        open: true,
      };
    case CLOSE_DEPARTMENT_PICKER:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};

const reducers = combineReducers({
  user: userReducer,
  department: departmentReducer,
  dataSet: dataSetReducer,
  conditionsPerConditionCategory: conditionsPerConditionCategoryReducer,
  date: dateReducer,
  form: formReducer,
  role: roleReducer,
  period: periodReducer,
  language: languageReducer,
  departmentHierarchy: departmentHierarchyReducer,
  departmentPicker: departmentPickerReducer,
  token: tokenReducer,
});

export default reducers;
