import React, { useEffect, useState } from 'react';
import Loading from '../Loading';
import { api } from '@/api';

export const withNavigation = (BaseComponent) => {
  const withNavigationComponent = (props) => {
    const { dataSet } = props;

    const [loading, setLoading] = useState(true);
    const [employees, setEmployees] = useState([]);
    const [activeEmployeeCount, setActiveEmployeeCount] = useState(null);
    const [activeCompaniesEmployeeCount, setActiveCompaniesEmployeeCount] =
      useState(null);
    const [companyNames, setCompanyNames] = useState([]);

    useEffect(() => {
      if (!dataSet) {
        return;
      }

      setLoading(true);
      api.navigation
        .execute(dataSet)
        .then((data) => {
          setEmployees(data.employees);
          setActiveEmployeeCount(data.activeEmployeesCount);
          setActiveCompaniesEmployeeCount(data.activeEmployeesAtCompanyCount);
          setCompanyNames(data.companyNames);
        })
        .catch((err) => {
          // TODO better error handling
          console.log('err', err);
        })
        .finally(() => {
          setLoading(false);
        });
    }, [dataSet]);

    if (loading) {
      return <Loading />;
    }

    return (
      <BaseComponent
        {...props}
        employees={employees}
        activeEmployeeCount={activeEmployeeCount ? activeEmployeeCount : 0}
        activeCompaniesEmployeeCount={
          activeCompaniesEmployeeCount ? activeCompaniesEmployeeCount : 0
        }
        companyNames={companyNames ? companyNames : undefined}
      />
    );
  };

  return withNavigationComponent;
};
