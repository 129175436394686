import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import config from './config';
import {
  getKey,
  setKey,
  getUrlParameterByName,
  logout,
  isDevelopmentEnvironment,
  removeKey,
} from './utils';
import { store } from './store';

import 'rc-slider/assets/index.css';
import './index.css';

import App from './App';
import { apiBuilder, setCurrentApi } from '@/api';
import { features } from '@/services/features';
import { monitorUnhandledErrors } from '@/services/logger';
import { JWTToken } from './api/token';

features.installCLI(window, config.featureApi);
// features.installUI(window);

const { tokenKey } = config.localStorage;
const minaSidorErrorRedirectParam = getUrlParameterByName('error_redirect');
let token = getUrlParameterByName('token');

if (minaSidorErrorRedirectParam) {
  setKey('minaSidorErrorRedirect', minaSidorErrorRedirectParam);
}

if (!token) {
  token = getKey(tokenKey);

  if (!token) {
    logout();
  }
} else {
  setKey(
    tokenKey,
    token,
    new Date().getTime() + config.localStorage.expiration
  );

  // This removes all query parameters
  window.history.pushState({}, document.title, '/');
}

if (token !== null) {
  const errorCallback = (/** @type {Response} */ response) => {
    if (response.status !== 401) {
      // TODO re-add: || response.status === 403
      return false;
    }

    const minaSidorErrorRedirect = getKey('minaSidorErrorRedirect');

    if (isDevelopmentEnvironment) {
      console.error('an error occured', response);
    }

    if (minaSidorErrorRedirect) {
      removeKey('minaSidorErrorRedirect');
      window.location.href = minaSidorErrorRedirect;

      return true;
    }

    return false;
  };

  /**
   * @param {JWTToken} jwtToken
   */
  const jwtTokenChange = (jwtToken) => {
    setKey(tokenKey, jwtToken.rawValue, jwtToken.payload.exp);
  };

  const statToolApi = apiBuilder({
    endpoint: config.backend.endpoint,
    token: new JWTToken(token),
    accessErrorCallback: errorCallback,
    jwtTokenChange,
  });

  setCurrentApi(statToolApi);
  monitorUnhandledErrors(statToolApi, window);
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
