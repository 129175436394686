import React from 'react';

export const previaLogo = (
  <svg viewBox="0 0 102 23" fillRule="evenodd" aria-labelledby="previa-logo">
    <title id="previa-logo">Previa</title>
    <path d="M82.08 8.23l-4.12 14.58c-.45.12-.93.19-1.44.19-1.74 0-3.44-.74-3.44-3.01 0-.7.32-2.08.8-3.84l2.24-7.92h5.96zm-18.97 0l-2.67 9.58c-.42 1.5-.55 2.12-.55 2.63 0 .73.67 1.02 1.54 1.02 2.96 0 8.22-4.48 8.22-7.46 0-1.99-3.11-.8-3.11-3.94a2.49 2.49 0 0 1 2.6-2.56c1.96 0 3.02 1.31 3.02 3.2 0 5.22-7.42 12.3-13.4 12.3-2.11 0-4.46-.83-4.46-3.2 0-.8.13-1.57.7-3.74l2.1-7.83h6zm38.89 0l-4.14 14.58a6.8 6.8 0 0 1-1.64.19c-1.8 0-2.92-.67-3.37-2.6h-.07a4.77 4.77 0 0 1-4.4 2.6c-3.6 0-5.3-2.82-5.3-6.34 0-4.61 3.73-9.32 8.48-9.32 1.8 0 3.4.92 3.8 2.88h.06l.54-1.99H102zM53.55 11.5c0-3.04-3.28-4.16-5.9-4.16-5.4 0-10.42 3.74-10.42 9.47 0 3.34 1.87 5.2 4.53 5.88l1.8-6.48c.52.06 1.64.16 3.05.16 3.09 0 6.94-1.25 6.94-4.87zM16.55 0c4.88 0 6.62 1.8 6.62 5.26 0 3.33-2.6 7.08-8.9 7.08H9.45l-2.8 10.21H0L6.25 0h10.3zm13.33 10.54c.77-1.22 2.4-3.2 4.27-3.2 1.77 0 2.7 1.18 2.7 2.78 0 1.9-1.25 2.98-2.86 2.98-1.64 0-2.54-1.21-2.54-2.6 0-.41.1-.7.23-1.05l-.07-.06a11.83 11.83 0 0 0-2.82 5.12l-2.22 8.04H20.5l3.93-14.32h6l-.61 2.31h.06zm62.55 9.32c-.45 1.1-1.51 2.15-2.63 2.15-.65 0-1.32-.23-1.32-1.35 0-1.38 1.35-6.09 2.5-9.64.52-1.6 1.06-2.5 2.12-2.5.94 0 1.64.93 1.64 2.56 0 1.54-1.28 6.38-2.31 8.78zm-47.39-4.1a8.5 8.5 0 0 1-1.32-.1l1.6-5.76c.3-1.06.88-1.95 1.84-1.95.96 0 1.38.64 1.38 1.6 0 .86-.96 3.84-1.38 4.64-.48.96-1.16 1.57-2.12 1.57zm-33.7-4.03c2.13 0 2.9-.55 3.67-1.86.7-1.19 1.8-4.61 1.8-6.86 0-2.01-1.32-2.4-2.77-2.4H12.7L9.6 11.73h1.74zM79.95 0c1.6 0 2.88 1.28 2.88 2.88 0 1.6-1.28 2.89-2.89 2.89a2.87 2.87 0 0 1-2.89-2.89c0-1.6 1.29-2.88 2.9-2.88z" />
  </svg>
);

export const falckLogo = (
  <svg viewBox="0 0 456.92 137.04">
    <g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M255.05,105.55h20.76l-24.46-64.62h-22.62l-24.46,64.62h20.53l3.82-11.31h22.62L255.05,105.55z
            M247.25,80.01h-14.62l7.27-19.88L247.25,80.01z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M159.89,105.55h19.7V83.82h24.36l6.24-16.12h-30.6v-9.67h32.15V40.93h-51.85V105.55z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M280.05,105.55h42.44l6.66-17.91h-29.4V40.93h-19.7V105.55z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M360.14,39.7c-19.91,0-33.82,13.79-33.82,33.54s13.91,33.54,33.82,33.54c12.45,0,22.67-5.57,29.59-16.11
            l-16.6-10.19c-2.72,4.84-7.26,7.5-12.8,7.5c-6.75,0-13.55-4.56-13.55-14.73c0-8.54,5.64-14.73,13.41-14.73
            c5.69,0,9.98,2.61,12.43,7.57l17.01-9.01C383.94,46.19,372.93,39.7,360.14,39.7z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            fill="currentColor"
            d="M432.85,105.55h24.07l-21.89-33.02l0.09-0.14l20.94-31.46h-24.02L418.43,64.4h-4.19V40.93h-19.7v64.62h19.7
            V81.93h4.37l0.07,0.12L432.85,105.55z"
          />
        </g>
      </g>
      <g>
        <path
          fill="currentColor"
          d="M85.5,70.31c2.37,0.14,4.88,0.36,7.28,0.74c7.2-12.63,20.05-16.71,20.05-16.71l24.21-54.28
          c0,0-9.73-0.98-12.13,4.85c-3.33,8.09-17.74,43.39-17.74,43.39S92.84,54.14,85.5,70.31z"
        />
        <path
          fill="currentColor"
          d="M12.74,52.01c0,0,3.35,10.45,10.65,10.58c1.04,0.02,11.87-0.02,16.86-0.03c-1.43-2.57-2.67-5.24-3.77-7.98
          L12.74,52.01z"
        />
        <path
          fill="currentColor"
          d="M45.62,84.1c0.41-0.15,5.45-2.15,8.72-3.5c-2.12-1.93-4.08-4.04-5.96-6.23l-16.8,4.49
          C31.58,78.86,38.94,86.61,45.62,84.1z"
        />
        <path
          fill="currentColor"
          d="M20.61,66.56c0,0,5.39,9.36,13.09,7.96c0.33-0.06,8.03-1.46,12.87-2.37c-1.79-2.26-3.39-4.65-4.9-7.12
          L20.61,66.56z"
        />
        <path
          fill="currentColor"
          d="M8.38,35.37c0,0,0.63,11.7,9.01,13.12c0.5,0.08,11.56,2.14,18.08,3.41c-0.91-2.8-1.77-5.79-2.46-8.77
          L8.38,35.37z"
        />
        <path
          fill="currentColor"
          d="M13.6,32.07c0.48,0.21,12.52,5.5,18.92,8.41c-0.49-2.95-0.91-6.44-0.99-9.48L8.36,16.5
          C8.36,16.5,6.1,28.77,13.6,32.07z"
        />
        <path
          fill="currentColor"
          d="M74.66,65.11c0.82-2.58,1.94-5.11,3.14-7.5l-7.39-11.92c0,0-4.17,8.98-0.4,13.75
          C70.19,59.67,73.51,63.68,74.66,65.11z"
        />
        <path
          fill="currentColor"
          d="M79.12,55.16c1.43-2.49,3.04-4.83,4.85-7.04l-6.61-16.09c0,0-6.04,8.2-2.73,14.19
          C74.83,46.57,78.23,53.38,79.12,55.16z"
        />
        <path
          fill="currentColor"
          d="M85.64,46.18c1.95-2.16,4.08-4.23,6.36-6.03l-3.86-20.95c0,0-8.31,6.26-6.31,13.67
          C81.87,32.99,85.05,44.11,85.64,46.18z"
        />
        <path
          fill="currentColor"
          d="M94.24,38.43c2.38-1.68,4.91-3.29,7.56-4.57l0.77-25.16c0,0-10.25,4.5-9.82,12.05L94.24,38.43z"
        />
        <path
          fill="currentColor"
          d="M120.82,1.77c0,0-12.11,1.99-13.1,8.76c-0.16,1.09-2.86,18.81-3.37,22.15c2.51-1.04,5.11-2.02,7.79-2.68
          L120.82,1.77z"
        />
        <path
          fill="currentColor"
          d="M110.77,83.95l-0.08-0.01l0.01-0.01c0.37-1.46,0.06-2.85-0.99-4.09c-4.84-5.74-21.9-7.88-31.92-7.28
          C61.05,58.41,59.54,39.79,59.54,39.79L12.46,0.22c0,0-4.72,8.95,0.8,13.35c3.51,2.81,37.5,29.3,39.55,30.91
          C55.57,60,63.53,69.14,69.58,74.82c3.67,3.45,5.36,6.84,3,9.62c-2.25,2.64-7.7,2.66-10.17,2.61c-2.2-1.51-4.2-3.09-6.29-4.86
          L0,109.75c0,0,7.57,6.14,14.3,2.94c0.12-0.06,38.05-20.12,47.53-25.14c2.76,1.8,5.5,3.43,8.52,4.83
          c7.78,0.13,13.2-6.77,10.39-13.38c0,0,19.86,0.18,24.1,5.28c0.28,0.33,0.37,0.71,0.34,1.24l-6.77-1.37c0,0-0.8,4.29,3.77,4.76
          c1.54,0.16,2.31-0.49,2.79-1.25l2.58,0.42c2.46,0.39,2.86,2.75,2.73,3.77c-0.21,1.68-1.65,3.23-2.35,3.71
          c-0.28-2.18-1.83-3.97-4.1-4.53c-3.93-0.96-20.05,0.22-23.22,2.49c-14.91,10.68-15.94,26.64-15.95,26.74c0,0,3.8,8.68,9.6,13.58
          c3.23,2.73,6.6,3.2,8.91,3.2c2.41,0,4.42-0.99,4.42-0.99s-11.8-7.77-15.06-18.38c0.3-1.05,4.62-12.61,11.56-17.56
          c2.81-2.01,23.84-4.56,23.84-4.56l3.55,5.76c0,0,5.97-3.92,5.57-10.16C116.81,87.55,113.49,84.44,110.77,83.95z"
        />
        <path
          fill="currentColor"
          d="M60.49,110.97c-1.53-6.24-1.27-9.34-1.04-10.24l-24.83,5.19l-17.03,10.78c0,0,8.27,5.2,15.39,0.67
          l17.11-11.45c0,0,1.35,7.62,1.94,9.87c2.29,8.57,10.89,4.67,10.89,4.67L60.49,110.97z"
        />
      </g>
      <path
        fill="currentColor"
        d="M79.66,109.79c0,0-2.4,3.91-3.74,7.6c0,0,2.53,4.31,6.3,7.75c4,3.66,8.24,6.97,13.44,7.17
        c2.42,0.09,6.2-2.1,6.2-2.1l-1.53-0.79C86.46,122.16,79.66,109.79,79.66,109.79z"
      />
    </g>
  </svg>
);

export const user = (
  <svg viewBox="0 0 20 21">
    <path d="M10.3 0c.95.1 1.88.32 2.7.78a6.12 6.12 0 0 1 3.1 5.42 5.92 5.92 0 0 1-1.25 3.44c3.28 2.21 5.12 6.29 5.15 10.2 0 .66-.54 1.3-1.22 1.13-.45-.11-.73-.56-.74-1.07v-.21c-.13-3.3-1.52-6.62-4.35-8.5l-.3-.19-.1.06a6.26 6.26 0 0 1-6.66-.05L6.6 11c-2.87 1.75-4.52 5.17-4.65 8.52v.4a1 1 0 0 1-1.28 1.04 1 1 0 0 1-.68-1c.07-4.01 1.79-8.03 5.12-10.31 0 0-.27-.38-.45-.7A6.01 6.01 0 0 1 9.54.02h.1c.04-.02.04-.02.1-.02h.56zm-.32 1.94A4.26 4.26 0 0 0 6.14 4.5c-.45 1.1-.38 2.4.2 3.44a4.25 4.25 0 0 0 3.63 2.15 4.24 4.24 0 0 0 4.05-3.06 4.08 4.08 0 0 0-4.04-5.08z" />
  </svg>
);

export const male = (
  <svg viewBox="0 0 24 48">
    <path d="M12 8.29a4.16 4.16 0 0 1-4.17-4.15A4.16 4.16 0 0 1 12 0c2.3 0 4.17 1.85 4.17 4.14A4.16 4.16 0 0 1 12 8.3zm6.23 2.54l5.62 13.36a1.86 1.86 0 0 1-1.72 2.58c-.74 0-1.43-.43-1.73-1.14l-3.65-8.66v10.4l3.14 18.46A1.86 1.86 0 0 1 18.04 48c-.9 0-1.7-.64-1.85-1.55l-3.16-18.6a1.9 1.9 0 0 1-.03-.32h-2c0 .1-.01.21-.03.31l-3.16 18.6a1.87 1.87 0 0 1-2.16 1.53 1.86 1.86 0 0 1-1.54-2.14l3.14-18.46v-10.4L3.6 25.63a1.87 1.87 0 0 1-3.45-1.44l5.63-13.36c.29-.7.97-1.15 1.72-1.15h4.17c.11 0 .22.02.33.04a2 2 0 0 1 .33-.04h4.17c.75 0 1.44.45 1.73 1.15z" />
  </svg>
);

export const female = (
  <svg viewBox="0 0 24 48">
    <path d="M12 8.29a4.16 4.16 0 0 1-4.17-4.15A4.16 4.16 0 0 1 12 0c2.3 0 4.17 1.85 4.17 4.14A4.16 4.16 0 0 1 12 8.3zm11.85 15.9a1.86 1.86 0 0 1-1.72 2.59c-.74 0-1.43-.43-1.73-1.15l-3.65-8.66v.97L20.87 32h-3.33l2.35 13.83A1.86 1.86 0 0 1 18.04 48c-.9 0-1.7-.64-1.85-1.55L13.74 32h-3.47L7.8 46.45a1.87 1.87 0 0 1-3.7-.62L6.47 32H3.13l4.12-14.07v-.97L3.6 25.63a1.87 1.87 0 0 1-3.45-1.44l5.63-13.36c.29-.7.97-1.15 1.72-1.15h4.17c.11 0 .22.02.33.04.1-.02.22-.04.33-.04h4.17c.75 0 1.44.45 1.73 1.15l5.62 13.36z" />
  </svg>
);

export const checklist = (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path
      d="M2 5.5L3.21429 7L7.5 3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12.5L3.21429 14L7.5 10"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 19.5L3.21429 21L7.5 17"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M22 19L12 19" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M22 12L12 12" strokeWidth="1.5" strokeLinecap="round" />
    <path d="M22 5L12 5" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
);

export const closeSquare = (
  <svg viewBox="0 0 24 24" fill="none">
    <path d="M10.0303 8.96967C9.73741 8.67678 9.26253 8.67678 8.96964 8.96967C8.67675 9.26256 8.67675 9.73744 8.96964 10.0303L10.9393 12L8.96966 13.9697C8.67677 14.2626 8.67677 14.7374 8.96966 15.0303C9.26255 15.3232 9.73743 15.3232 10.0303 15.0303L12 13.0607L13.9696 15.0303C14.2625 15.3232 14.7374 15.3232 15.0303 15.0303C15.3232 14.7374 15.3232 14.2625 15.0303 13.9697L13.0606 12L15.0303 10.0303C15.3232 9.73746 15.3232 9.26258 15.0303 8.96969C14.7374 8.6768 14.2625 8.6768 13.9696 8.96969L12 10.9394L10.0303 8.96967Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0574 1.25H11.9426C9.63424 1.24999 7.82519 1.24998 6.41371 1.43975C4.96897 1.63399 3.82895 2.03933 2.93414 2.93414C2.03933 3.82895 1.63399 4.96897 1.43975 6.41371C1.24998 7.82519 1.24999 9.63422 1.25 11.9426V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H12.0574C14.3658 22.75 16.1748 22.75 17.5863 22.5603C19.031 22.366 20.1711 21.9607 21.0659 21.0659C21.9607 20.1711 22.366 19.031 22.5603 17.5863C22.75 16.1748 22.75 14.3658 22.75 12.0574V11.9426C22.75 9.63423 22.75 7.82519 22.5603 6.41371C22.366 4.96897 21.9607 3.82895 21.0659 2.93414C20.1711 2.03933 19.031 1.63399 17.5863 1.43975C16.1748 1.24998 14.3658 1.24999 12.0574 1.25ZM3.9948 3.9948C4.56445 3.42514 5.33517 3.09825 6.61358 2.92637C7.91356 2.75159 9.62177 2.75 12 2.75C14.3782 2.75 16.0864 2.75159 17.3864 2.92637C18.6648 3.09825 19.4355 3.42514 20.0052 3.9948C20.5749 4.56445 20.9018 5.33517 21.0736 6.61358C21.2484 7.91356 21.25 9.62177 21.25 12C21.25 14.3782 21.2484 16.0864 21.0736 17.3864C20.9018 18.6648 20.5749 19.4355 20.0052 20.0052C19.4355 20.5749 18.6648 20.9018 17.3864 21.0736C16.0864 21.2484 14.3782 21.25 12 21.25C9.62177 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12C2.75 9.62177 2.75159 7.91356 2.92637 6.61358C3.09825 5.33517 3.42514 4.56445 3.9948 3.9948Z"
    />
  </svg>
);

export const layers = (
  <svg viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <path
      d="M4.97883 9.68508C2.99294 8.89073 2 8.49355 2 8C2 7.50645 2.99294 7.10927 4.97883 6.31492L7.7873 5.19153C9.77318 4.39718 10.7661 4 12 4C13.2339 4 14.2268 4.39718 16.2127 5.19153L19.0212 6.31492C21.0071 7.10927 22 7.50645 22 8C22 8.49355 21.0071 8.89073 19.0212 9.68508L16.2127 10.8085C14.2268 11.6028 13.2339 12 12 12C10.7661 12 9.77318 11.6028 7.7873 10.8085L4.97883 9.68508Z"
      strokeWidth="1.5"
    />
    <path
      d="M22 12C22 12 21.0071 12.8907 19.0212 13.6851L16.2127 14.8085C14.2268 15.6028 13.2339 16 12 16C10.7661 16 9.77318 15.6028 7.7873 14.8085L4.97883 13.6851C2.99294 12.8907 2 12 2 12"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M22 16C22 16 21.0071 16.8907 19.0212 17.6851L16.2127 18.8085C14.2268 19.6028 13.2339 20 12 20C10.7661 20 9.77318 19.6028 7.7873 18.8085L4.97883 17.6851C2.99294 16.8907 2 16 2 16"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export const restart = (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      d="M2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12Z"
      strokeWidth="1.5"
    />
    <path
      d="M15.9775 8.71452L15.5355 8.2621C13.5829 6.26318 10.4171 6.26318 8.46447 8.2621C6.51184 10.261 6.51184 13.5019 8.46447 15.5008C10.4171 17.4997 13.5829 17.4997 15.5355 15.5008C16.671 14.3384 17.1462 12.7559 16.9611 11.242M15.9775 8.71452H13.3258M15.9775 8.71452V6"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const search = (
  <svg viewBox="0 0 13 13">
    <path
      d="M2.94 9.04a5.42 5.42 0 0 1-1-1.99A5.81 5.81 0 0 1 7.28 0h.22a5.8 5.8 0 0 1 5.08 3.32c1.52 3.37-1.2 7.91-5.08 7.96a5.67 5.67 0 0 1-3.47-1.18c-.9.93-1.83 1.85-2.77 2.75-.55.48-1.72-.3-1.05-1.05.9-.93 1.81-1.85 2.74-2.75zm4.42-7.56c-3.25.07-5.51 4.72-2.74 7.24 2.07 1.88 6.07.94 6.83-2.04.64-2.47-1.5-5.21-4.08-5.2z"
      fill="currentColor"
    />
  </svg>
);

export const hamburger = (
  <svg viewBox="0 0 22 18">
    <path d="M21 18H1a1 1 0 0 1 0-2h20a1 1 0 0 1 0 2zm0-8H1a1 1 0 0 1 0-2h20a1 1 0 0 1 0 2zm0-8H1a1 1 0 0 1 0-2h20a1 1 0 0 1 0 2z" />
  </svg>
);

export const arrow = (
  <svg width="9px" height="14px" viewBox="0 0 9 14" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g stroke="currentColor" strokeWidth="2">
        <polyline points="1.03597517 1 7 6.98195833 1 13" />
      </g>
    </g>
  </svg>
);

export const close = (
  <svg width="12px" height="12px" viewBox="0 0 12 12">
    <path d="M6 4.6L10.6 0 12 1.4 7.4 6l4.6 4.6-1.4 1.4L6 7.4 1.4 12 0 10.6 4.6 6 0 1.4 1.4 0 6 4.6z" />
  </svg>
);

export const wordBalloon = (
  <svg width="21" height="19" viewBox="0 0 21 19">
    <path d="M21 7.41c0 3.331-2.219 6.17-5.251 7.093l1.536 3.678c.092.212.187.437.044.628a.476.476 0 0 1-.613.131l-3.845-2.973-1.576-1.147H7.383C3.324 14.82 0 11.483 0 7.41S3.324 0 7.383 0h6.236C17.676 0 21 3.337 21 7.41z" />
  </svg>
);

export const information = (
  <svg width="7" height="17" viewBox="0 0 128 285">
    <g fill="currentColor" fillRule="evenodd">
      <path d="M35.974 38.291C35.8 17.64 52.633.633 73.284.46c20.999 0 37.485 16.486 37.485 37.658.173 20.651-16.313 37.658-36.79 38.005-20.651.173-37.659-16.834-38.005-37.832zM127.082 253.132c-.521 3.124-2.43 6.421-4.686 8.851-13.709 14.057-30.196 22.386-49.979 22.734-8.851.174-18.048.52-26.552-1.389-22.56-4.686-35.402-23.949-31.757-46.508 4.338-27.246 9.197-54.144 13.883-81.216 1.389-8.157 3.124-16.313 4.165-24.47.521-2.95.174-6.247-.347-9.023-1.041-5.553-3.817-7.983-9.197-8.851-2.95-.347-5.901-.174-8.677-.867-3.471-1.041-7.462-2.256-10.239-4.338-4.859-3.645-3.817-10.759 1.736-13.189 2.776-1.389 6.247-2.082 9.371-2.082 12.322-.174 24.816 0 36.964 0h36.964c8.851 0 13.363 4.512 14.578 13.362 1.214 8.851-1.91 17.181-3.471 25.685-4.512 25.51-9.372 50.847-13.883 76.183-1.562 8.677-3.125 17.354-4.512 26.205-.347 2.604-.174 5.553 0 8.157 1.561 11.279 7.982 15.445 18.742 12.668 3.297-.867 6.248-2.776 9.372-4.338 2.95-1.389 5.9-3.297 9.023-4.512 5.377-2.086 9.716 1.385 8.502 6.938z" />
    </g>
  </svg>
);

export const calendar = (
  <svg width="27" height="28" viewBox="0 0 27 28">
    <path
      d="M4.91 0v3.802H0V28h27V3.802h-4.558V0h-2.104v3.802H7.013V0H4.909zm19.986 25.926H2.104V11.062h22.792v14.864zm-4.558-20.05h4.558v3.112H2.104V5.877h18.234z"
      fill="currentColor"
      fillRule="nonzero"
    />
  </svg>
);

export const happyFace = (
  <svg width="62" height="62" viewBox="0 0 62 62">
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <circle
        stroke="currentColor"
        strokeWidth="3"
        fill="#fff"
        cx="29"
        cy="29"
        r="29"
      />
      <g fill="currentColor" fillRule="nonzero">
        <path d="M42.058 18.073a4.722 4.722 0 00-4.716 4.72c0 .572.466 1.036 1.037 1.036a1.04 1.04 0 001.037-1.037 2.645 2.645 0 012.642-2.644 2.646 2.646 0 012.645 2.644c0 .573.466 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.723 4.723 0 00-4.719-4.719zM18.427 22.795c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.722 4.722 0 00-4.716-4.719 4.723 4.723 0 00-4.719 4.719c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 2.646 2.646 0 012.645-2.645 2.645 2.645 0 012.642 2.645zM28.743 41.488c4.92 0 9.793-1.903 13.365-5.224a1.037 1.037 0 00-1.41-1.52c-3.192 2.966-7.55 4.667-11.953 4.667a17.566 17.566 0 01-11.644-4.387 1.033 1.033 0 00-1.463.091 1.035 1.035 0 00.091 1.462 19.627 19.627 0 0013.014 4.911z" />
      </g>
    </g>
  </svg>
);

export const sadFace = (
  <svg width="62" height="62" viewBox="0 0 62 62">
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <circle
        stroke="currentColor"
        strokeWidth="3"
        fill="#fff"
        cx="29"
        cy="29"
        r="29"
      />
      <g fill="currentColor" fillRule="nonzero">
        <path d="M42.058 18.073a4.722 4.722 0 00-4.716 4.72c0 .572.466 1.036 1.037 1.036a1.04 1.04 0 001.037-1.037 2.645 2.645 0 012.642-2.644 2.646 2.646 0 012.645 2.644c0 .573.466 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.723 4.723 0 00-4.719-4.719zM18.427 22.795c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.722 4.722 0 00-4.716-4.719 4.723 4.723 0 00-4.719 4.719c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 2.646 2.646 0 012.645-2.645 2.645 2.645 0 012.642 2.645zM29.076 33.467c-4.921 0-9.793 1.903-13.366 5.224a1.037 1.037 0 001.41 1.52c3.192-2.966 7.55-4.667 11.953-4.667 4.307 0 8.442 1.558 11.644 4.387a1.033 1.033 0 001.463-.091 1.035 1.035 0 00-.09-1.462 19.627 19.627 0 00-13.014-4.911z" />
      </g>
    </g>
  </svg>
);

export const SadFace = ({ size, ...props }) => (
  <svg width={size} height={size} viewBox="0 0 62 62" {...props}>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <circle
        stroke="currentColor"
        strokeWidth="3"
        fill="#fff"
        cx="29"
        cy="29"
        r="29"
      />
      <g fill="currentColor" fillRule="nonzero">
        <path d="M42.058 18.073a4.722 4.722 0 00-4.716 4.72c0 .572.466 1.036 1.037 1.036a1.04 1.04 0 001.037-1.037 2.645 2.645 0 012.642-2.644 2.646 2.646 0 012.645 2.644c0 .573.466 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.723 4.723 0 00-4.719-4.719zM18.427 22.795c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 4.722 4.722 0 00-4.716-4.719 4.723 4.723 0 00-4.719 4.719c0 .573.467 1.037 1.037 1.037a1.04 1.04 0 001.037-1.037 2.646 2.646 0 012.645-2.645 2.645 2.645 0 012.642 2.645zM29.076 33.467c-4.921 0-9.793 1.903-13.366 5.224a1.037 1.037 0 001.41 1.52c3.192-2.966 7.55-4.667 11.953-4.667 4.307 0 8.442 1.558 11.644 4.387a1.033 1.033 0 001.463-.091 1.035 1.035 0 00-.09-1.462 19.627 19.627 0 00-13.014-4.911z" />
      </g>
    </g>
  </svg>
);

export const chevronRight = (
  <svg width="41" height="70" viewBox="0 0 41 70">
    <path
      d="M38.9 37.8L6.8 68.5C5.2 70 2.7 70 1.1 68.4.4 67.7 0 66.7 0 65.6V4.3c0-2.2 1.8-4 4-4 1 0 2 .4 2.8 1.1L38.9 32c1.6 1.5 1.7 4.1 0 5.8z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const chevronDown = (
  <svg width="14" height="9">
    <path
      d="M13 1.036L7.018 7 1 1"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export const chevronUp = (
  <svg width="14" height="9">
    <path
      d="M13 1.036L7.018 7 1 1"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
      transform="rotate(180,7,4.5)"
    />
  </svg>
);

export const smallChevronRight = (
  <svg width="9" height="14">
    <path
      d="M1.036 13L7 7.018 1 1"
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
);

export const document = (
  <svg width="22" height="21" viewBox="0 0 22 21">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M14.674 18.656H1.65V2.992h2.002v.66c0 .418.352.77.77.77a.78.78 0 00.77-.77v-.66h2.222v.66c0 .418.352.77.77.77a.78.78 0 00.77-.77v-.66h2.222v.66c0 .418.352.77.77.77a.78.78 0 00.77-.77v-.66h2.002v7.37l1.54-1.54V2.618a1.16 1.16 0 00-1.166-1.166h-2.398v-.66a.78.78 0 00-.77-.77.78.78 0 00-.77.77v.66H8.932v-.66a.78.78 0 00-.77-.77.78.78 0 00-.77.77v.66H5.17v-.66a.78.78 0 00-.77-.77.78.78 0 00-.77.77v.66H1.276A1.16 1.16 0 00.11 2.618V19.03a1.16 1.16 0 001.166 1.166H15.07c.638 0 1.166-.528 1.166-1.166v-3.278l-1.54 1.54v1.364h-.022z" />
      <path d="M12.452 11.374a.78.78 0 00-.77-.77H4.664a.78.78 0 00-.77.77c0 .418.352.77.77.77h7.018a.78.78 0 00.77-.77zM4.664 8.294h7.018a.78.78 0 00.77-.77.78.78 0 00-.77-.77H4.664a.78.78 0 00-.77.77c0 .418.33.77.77.77zM3.894 15.224c0 .44.33.77.77.77h5.764l.308-1.364c.022-.066.022-.132.044-.176H4.664c-.44 0-.77.352-.77.77zM11.924 17.05l1.694-.374c.066-.022.132-.066.198-.11l.506-.528-1.87-1.87-.506.506a.357.357 0 00-.11.198l-.396 1.716c-.066.286.198.528.484.462zM13.112 13.53l1.848 1.87 4.4-4.4-1.848-1.87zM20.878 8.932l-1.298-1.32c-.176-.154-.418-.154-.572 0l-.88.88 1.87 1.87.88-.88a.352.352 0 000-.55z" />
    </g>
  </svg>
);

export const file = (
  <svg width="17" height="20" viewBox="0 0 17 20">
    <g fill="currentColor" fillRule="nonzero">
      <path d="M14.696 17.656H1.65V1.992H14.718v7.37h1.54V1.618A1.16 1.16 0 0015.092.452H1.276A1.16 1.16 0 00.11 1.618V18.03a1.16 1.16 0 001.166 1.166h13.816c.638 0 1.166-.528 1.166-1.166V9.362h-1.54v8.294h-.022z" />
      <path d="M4.664 6.54h7.018a.78.78 0 00.77-.77.78.78 0 00-.77-.77H4.664a.78.78 0 00-.77.77c0 .418.33.77.77.77zM8.89 8.159a.78.78 0 00-1.088 0l-2.134 2.134a.78.78 0 000 1.088.78.78 0 001.088 0L8.89 9.247a.78.78 0 000-1.088z" />
      <path d="M7.802 8.159a.78.78 0 011.088 0l2.135 2.134a.78.78 0 010 1.088.78.78 0 01-1.09 0L7.803 9.247a.78.78 0 010-1.088z" />
      <path d="M8 9c.543 0 1 .353 1 .773v4.454c0 .42-.457.773-1 .773-.543 0-1-.353-1-.773V9.773C7 9.353 7.457 9 8 9z" />
    </g>
  </svg>
);

export const notes = (
  <svg width="17" height="20" viewBox="0 0 17 20">
    <g fill="none" fillRule="evenodd">
      <path
        stroke="currentColor"
        strokeWidth="1.53"
        strokeLinecap="round"
        d="M1 18.5h15"
      />
      <path
        d="M1.478 16.08l2.71-.598c.106-.036.212-.106.318-.176l.81-.845-2.993-2.992-.81.81a.571.571 0 00-.175.316l-.634 2.746c-.106.457.317.845.774.739zM3.38 10.448l2.956 2.992 7.04-7.04-2.957-2.992zM15.805 3.091L13.728.98c-.282-.246-.669-.246-.915 0l-1.408 1.408 2.992 2.992 1.408-1.408a.564.564 0 000-.88z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const uploadArrow = (
  <svg width="14" height="13" viewBox="0 0 14 13">
    <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="2">
      <path d="M7 2.5v10.4M1 8l6-6 6 6" />
    </g>
  </svg>
);

export const downloadArrow = (
  <svg viewBox="0 0 15 21">
    <g fill="none" fillRule="evenodd">
      <path
        d="M15 19.05V21H0v-1.95h15zM8.502 0v13.2l4.751-4.625 1.413 1.375L7.5 16.925.334 9.95l1.413-1.375L6.498 13.2V0h2.004z"
        fill="#fff"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export const checkmark = (
  <svg width="21" height="21" viewBox="0 0 22 22">
    <path
      d="M9.055 13.703l-2.566-3.405a1.5 1.5 0 1 0-2.396 1.805l3.61 4.792c.257.34.631.54 1.023.587a1.5 1.5 0 0 0 1.491-.497l7.713-9.192a1.5 1.5 0 1 0-2.298-1.929l-6.577 7.839zM11 22C4.925 22 0 17.075 0 11S4.925 0 11 0s11 4.925 11 11-4.925 11-11 11z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export const shortcut = (
  <svg width="11" height="13" viewBox="0 0 11 13">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M3.19 0L11 3.13 5.86 9.68 5.12 7c-2.3.99-4.9 2.95-.61 6 0 0-5.84-.62-4.23-6.11 0 0 .74-2.62 3.74-3.87L3.2 0z"
    />
  </svg>
);
export const lock = (
  <svg viewBox="0 0 448 512">
    <path
      fill="currentColor"
      d="M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"
    />
  </svg>
);
export const questionMark = (
  <svg viewBox="0 0 100 100">
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="currentColor"
      strokeWidth="3"
      fill="transparent"
    />
    <text
      x="50"
      y="50"
      fill="currentColor"
      textAnchor="middle"
      dominantBaseline="central"
      style={{ fontSize: 60, fontFamily: 'sans-serif' }}
    >
      ?
    </text>
  </svg>
);

export const deselectAll = (
  <svg viewBox="0 0 18 18">
    <rect
      x="5"
      y="5"
      width="12"
      height="12"
      rx="1"
      stroke="currentColor"
      fill="white"
      strokeWidth="1"
    />
    <rect
      x="1"
      y="1"
      width="12"
      height="12"
      rx="1"
      stroke="currentColor"
      fill="white"
      strokeWidth="1"
    />
  </svg>
);

export const dotMenu = (
  <svg viewBox="0 0 16 16">
    <circle cx="3" cy="8" r="1.5" fill="currentColor" />
    <circle cx="8" cy="8" r="1.5" fill="currentColor" />
    <circle cx="13" cy="8" r="1.5" fill="currentColor" />
  </svg>
);

export const funnel = (
  <svg viewBox="0 0 20 20">
    <path
      fill="#000000"
      d="M16.23 3.307c-0.396-0.268-0.949-0.504-1.643-0.702-1.366-0.39-3.172-0.605-5.087-0.605s-3.722 0.215-5.087 0.605c-0.694 0.198-1.246 0.434-1.643 0.702-0.637 0.43-0.77 0.886-0.77 1.193v0.5c0 0.428 0.321 1.133 0.639 1.609l4.891 7.336c0.251 0.376 0.471 1.103 0.471 1.555v3c0 0.173 0.090 0.334 0.237 0.425 0.080 0.050 0.171 0.075 0.263 0.075 0.076 0 0.153-0.018 0.224-0.053l2-1c0.169-0.085 0.276-0.258 0.276-0.447v-2c0-0.452 0.22-1.179 0.471-1.555l4.891-7.336c0.317-0.476 0.639-1.182 0.639-1.609v-0.5c0-0.307-0.134-0.763-0.77-1.193zM4.688 3.567c1.279-0.365 2.988-0.567 4.812-0.567s3.534 0.201 4.812 0.567c1.378 0.394 1.688 0.816 1.688 0.933s-0.31 0.54-1.688 0.933c-1.279 0.365-2.988 0.567-4.812 0.567s-3.534-0.201-4.812-0.567c-1.378-0.394-1.688-0.816-1.688-0.933s0.31-0.54 1.688-0.933zM10.639 13.391c-0.358 0.537-0.639 1.464-0.639 2.109v1.691l-1 0.5v-2.191c0-0.646-0.281-1.572-0.639-2.109l-4.88-7.32c0.274 0.117 0.585 0.226 0.932 0.324 1.366 0.39 3.172 0.605 5.087 0.605s3.722-0.215 5.087-0.605c0.346-0.099 0.658-0.207 0.932-0.325l-4.88 7.32z"
    ></path>
  </svg>
);
